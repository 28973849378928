import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../../hooks/AuthProvider";
import logo from '../../images/logo.png'
import ojkWhite from '../../images/ojk-white.png'
import bgLogin from '../../images/bg-login.png'
export default function Login() {
    const [input, setInput] = useState({
        email: "",
        password: "",
    });
    const [errorMessage,setErrorMessage]=useState("");
    const auth = useAuth();
    const handleSubmitEvent = async e => {
        e.preventDefault();
        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(input.email)) {
            alert("email not valid");
            return;
        }
        if (input.email !== "" && input.password !== "") {
            let result=await auth.loginAction(input);
            if(result!=""){
                setErrorMessage(result)
            }
            return;
        }else{
            alert("please provide a valid input");
        }    
    };

    const handleInput = (e) => {
        const { name, value } = e.target;
        setInput((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    useEffect(() => {
        if(localStorage.getItem("access_token")){
            auth.validateToken(localStorage.getItem("access_token"))
        }
    },[]);


    return (
        <div className="mb-20 container mx-auto max-w-screen-sm">
            <div className="p-6 bg-gray-100/30 border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                    <div>
                        <img src={logo} alt="Logo"/>
                    </div>
                    <div className="place-self-end">
                        <div className="grid grid-cols-3 gap-2">
                            <div className="col-span-2 place-self-end"><span className="text-xs text-white">Terdaftar dan Diawasi oleh</span></div>
                            <div><img src={ojkWhite} alt="Ojk"/></div>
                        </div>
                    </div>
                    <div>
                        <img className="m-auto w-1/2 sm:w-3/4 md:w-3/4 lg:w-full" src={bgLogin} alt="-"/>
                    </div>
                    <div>
                        <form className="max-w-sm mx-auto" onSubmit={handleSubmitEvent}>
                            <div className="mb-3">
                                <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 text-white">Email</label>
                                <input className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                                    type="email"
                                    id="email"
                                    name="email"
                                    aria-describedby="user-email"
                                    aria-invalid="false"
                                    placeholder="your@email.com" 
                                    onChange={handleInput}
                                    required 
                                    autoFocus
                                />
                            </div>
                            <div className="mb-5">
                                <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900 text-white">Password</label>
                                <input className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                                    type="password"
                                    id="password"
                                    name="password"
                                    aria-describedby="user-password"
                                    aria-invalid="false"
                                    onChange={handleInput}
                                    required 
                                />
                            </div>
                            {/*<div className="flex items-start mb-5">
                                <div className="flex items-center h-5">
                                <input id="remember" type="checkbox" value="" className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800"/>
                                </div>
                                <label htmlFor="remember" className="ms-2 text-sm font-medium text-gray-300">Remember me</label>
                            </div>*/}
                            <button className="text-white bg-green-700 border border-gray-200 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Login</button>
                            {errorMessage!=="" &&
                                <div className="mt-5 flex items-center p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400" role="alert">
                                    <svg className="flex-shrink-0 inline w-4 h-4 me-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
                                    </svg>
                                    <span className="sr-only">Info</span>
                                    <div>
                                        {errorMessage}
                                    </div>
                                </div>
                            }
                        </form>
                    </div>
                </div>
                <div className="text-center mt-3 animate-bounce">
                    <Link className="font-medium text-xs text-white hover:underline" to="/resetpass">Forget Password(validate Email)</Link>
                </div>
            </div>
        </div>
    )
}