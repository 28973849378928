import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AuthProvider from "./hooks/AuthProvider";
import PrivateRoute from "./middlewares/PrivateRoute";
import Layout from "./views/Layout";
import Login from "./views/pages/Login";
import Portfolio from "./views/pages/Portfolio";
import ResetPass from "./views/pages/ResetPass";
import NoPage from "./views/pages/NoPage";

function App() {
  return (
    <BrowserRouter basename="/member-area">
      <AuthProvider>
        <div className="App">
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/resetpass" element={<ResetPass />} />
            <Route element={<PrivateRoute />}>
              <Route path="/portfolio" element={<Portfolio />} />
            </Route>
          </Routes>
        </div>
      </AuthProvider>
    </BrowserRouter>
  );
  /*return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Login />} />
          <Route path="portfolio" element={<Portfolio />} />
          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );*/
}

export default App;
