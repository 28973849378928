import { useContext, createContext, useState } from "react";
import { useNavigate } from "react-router-dom";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(localStorage.getItem("access_token") || "");
  const navigate = useNavigate();
  const loginAction = async (data) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/rlogin`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        });
        const res = await response.json();
        if (res.user) {
            setUser(res.user);
            setToken(res.user.token);
            localStorage.setItem("access_token", res.user.token);
            localStorage.setItem("fullname", res.user.name);
            localStorage.setItem("phone", res.user.phone);
            navigate("/portfolio");
            return;
        }
        throw new Error(res.message);
    } catch (err) {
        return err.message
    }
  };
  const validateToken = async (token) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/profile`, {
            //method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            },
        });
        const res = await response.json();
        if (res.user) {
            navigate("/portfolio");
            return;
        }
        throw new Error(res.message);
    } catch (err) {
        console.error(err);
    }
  };

  const logOutAction = async (token) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/rlogin`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            },
        });
        const res = await response.json();
        if (res.message) {
            setUser(null);
            setToken("");
            localStorage.removeItem("access_token");
            localStorage.removeItem("fullname");
            localStorage.removeItem("phone");
            navigate("/");
            return;
        }
        throw new Error(res.message);
    } catch (err) {
        console.error(err);
    }
  };
  const resetPasswordAction = async (data) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/sresetpassword`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        });
        const res = await response.json();
        
        if (res.status) {
            return {
                'status':true,
                'message': res.message
            };
        }
        throw new Error(res.message);
    } catch (err) {
        return {
            'status':false,
            'message': err.message
        };
    }
  };
  const logOut = () => {
    setUser(null);
    setToken("");
    localStorage.removeItem("access_token");
    localStorage.removeItem("fullname");
    localStorage.removeItem("phone");
    navigate("/");
  };

  return (
    <AuthContext.Provider value={{ token, user, validateToken, loginAction, resetPasswordAction, logOutAction }}>
      {children}
    </AuthContext.Provider>
  );

};

export default AuthProvider;

export const useAuth = () => {
  return useContext(AuthContext);
};